import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query SidebarQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="sidebar">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.jpeg"
        width={75}
        height={75}
        quality={95}
        alt="Profile picture"
      />
      <h5>{author.name}</h5>
      <p>{author?.summary || null}</p>
      {` `}
      <a href={`https://twitter.com/${social?.twitter || ``}`}>
        You should follow me on Twitter
      </a>
      <p />
      <a href="/about/">About me</a>
      <br />
      <a href="/resume/">My resume</a>
    </div>
  )
}

export default Sidebar
